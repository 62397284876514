

	@import '../brand/2022-colors';

	@import '@xlts.dev/angular/angular-csp';
	@import 'angular-ui-bootstrap/dist/ui-bootstrap-csp';
	@import '@discover/unified-ui/src/colors/colors';
	@import '@discover/unified-ui/src/utilities/cursor/cursor';
	@import '@discover/unified-ui/src/utilities/color-utilities';
	@import '@discover/unified-ui/src/utilities/borders/borders';
	@import '@discover/unified-ui/src/utilities/fonts/font-weight';
	@import '@discover/unified-ui/src/utilities/fonts/font-families';
	@import '@discover/unified-ui/src/utilities/fonts/font-sizes';
	@import '@discover/unified-ui/src/utilities/display';
	@import '@discover/unified-ui/src/utilities/flex/flex.mixins';
	@import '@discover/unified-ui/src/utilities/flex/flex';
	@import '@discover/unified-ui/src/utilities/icons/icons';
	@import '@discover/unified-ui/src/utilities/overflow';
	@import '@discover/unified-ui/src/utilities/spacing';
	@import '@discover/unified-ui/src/utilities/widths-and-heights';
	@import '@discover/unified-ui/src/utilities/positioning';
	@import '@discover/unified-ui/src/utilities/float';
	@import '@discover/unified-ui/src/utilities/text-align';
	@import '@discover/unified-ui/src/utilities/text-transform';
	@import '@discover/unified-ui/src/utilities/text/white-space';
	@import '@discover/unified-ui/src/utilities/opacity';
	@import '@discover/unified-icons/dist/main';


	// this should only exist until unified-angular has been updated to new icon pack
	// this should only exist until unified-angular has been updated to new icon pack
	@import 'cross-product/icon-update-shim.scss';
	// this should only exist until unified-angular has been updated to new icon pack
	// this should only exist until unified-angular has been updated to new icon pack


	@import 'alert-subscription-templates';
	@import 'app-variables/_colors';
	@import 'app-variables/_fonts';
	@import 'app-variables/_icon-codepoints';
	@import 'app-variables/_measurements';
	@import 'app-variables/_media-queries';
	@import 'app-variables/_mixins';
	@import 'app-variables/_app-layering-order';
	@import 'cross-product/utilities/_autofill-dark-mode';
	@import 'cross-product/utilities/_animations';
	@import 'cross-product/utilities/_interactive-states';
	@import 'cross-product/utilities/_spacing';
	@import 'cross-product/utilities/_text';
	@import 'cross-product/utilities/_max-width';
	@import 'cross-product/utilities/_flex-helpers';
	@import 'cross-product/utilities/_columns';
	@import 'cross-product/utilities/_scrollbars';
	@import 'cross-product/utilities/replaced-in-npm';
	@import 'cross-product/_popovers';
	@import 'cross-product/_priority-icon';
	@import '_object-explorers';
	@import 'common';
	@import 'components/ag-grid';
	@import 'components/checkbox-button';
	@import 'components/color-bar';
	@import 'components/command-palette';
	@import 'components/dashboard-list-tabs-editor.component';
	@import 'components/date-range.component.scss';
	@import 'components/inline-help.scss';
	@import 'components/formatted-table';
	@import 'components/multiselect';
	@import 'components/rounded-grouping';
	@import 'components/pills.component.scss';
	@import 'conversations/transcript-piece';
	@import 'conversations/conversation-sentence';
	@import 'conversations/conversation-spine';
	@import 'components/words-selection';
	@import 'components/select-attributes';
	@import 'components/share-table';
	@import 'components/labeled-rule';
	@import 'components/token-input';
	@import 'contextmenu';
	@import 'cross-product/basic-elements';
	@import 'cross-product/buttons';
	@import 'cross-product/button-colors';
	@import 'cross-product/dropdown-menu';
	@import 'cross-product/helper-classes';
	@import 'cross-product/inputs';
	@import 'cross-product/pagination';
	@import 'alerts';
	@import 'an-document-explorer';
	@import '3rd-party-overwrites';
	@import 'accounts/security-audit';
	@import 'accounts/beta-features';
	@import 'bootstrap-custom';
	@import 'dashboard-filters';
	@import 'dashboard-grid';
	@import 'dashboard-tabs';
	@import 'dashboard-templates';
	@import 'dashboards';
	@import 'dialogs/about';
	@import 'dialogs/advanced-settings-dialog';
	@import 'dialogs/custom-dialog';
	@import 'dialogs/dashboard-props';
	@import 'dialogs/dashboard-share';
	@import 'dialogs/organization-enrichment-wizard-upload-modal';
	@import 'discussions';
	@import 'dock-and-float-panels';
	@import 'drilling-menu';
	@import 'drivers';
	@import 'dropdowns';
	@import 'experimental-ui';
	@import 'e2e-test-styles';
	@import 'filter-building';
	@import 'filters';
	@import 'flip-container';
	@import 'floating-panels';
	@import 'global-notifications';
	@import 'header';
	@import 'home';
	@import 'impersonation-warning';
	@import 'interaction-explorer';
	@import 'sampled-audits';
	@import 'table-menu';
	@import 'loader';
	@import 'log-grid';
	@import 'login';
	@import 'master-account';
	@import 'metrics/custom-metrics';
	@import 'metrics';
	@import 'keyboard-navigation';
	@import 'ng-right-click';
	@import 'node-hierarchy';
	@import 'notifications-and-alerts';
	@import 'pdf-export';
	@import 'schedules';
	@import 'scorecards';
	@import 'system-administration-beta-features';
	@import 'system-administration';
	@import 'user-management';
	@import 'widget-grid';
	@import 'widget-linking';
	@import 'widget-settings';
	@import 'widget';
	@import 'widgets/an-preview-widget';
	@import 'widgets/cb-preview';
	@import 'widgets/cb-widget';
	@import 'widgets/content-widget';
	@import 'widgets/drill-breadcrumbs';
	@import 'widgets/flex-widget';
	@import 'widgets/key-metric-widget';
	@import 'widgets/metric-widget';
	@import 'widgets/recolor-modal';
	@import 'widgets/selector-widget';
	@import 'widgets/simple-table';
	@import 'widgets/slick-table';
	@import 'widgets/cb-text-editor';
	@import 'widgets/tree-widget';
	@import 'brooklyn-ui';
	@import 'browser-tweaks';
	@import 'doc-explorer';
	@import 'appearance';
	@import 'add-widget-toolbar';
	@import 'grid-context-menu';
	@import 'components/color-picker';
	@import 'components/custom-toggle';

	:root {
		--font-standard: #{$font-standard};
		--qualtrics-icons: 'qualtrics-icons', 'engagor-icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		--font-monospace: #{$font-monospace};
		--font-serif: #{$font-serif};
		--font-sans-serif: #{$font-sans-serif};
	}
