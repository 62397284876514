.br-about-dialog .modal-dialog {
	background: var(--white);
	width: 430px;
}
.application-dark-mode {
	.br-about-image img {
		height: 30px;
	}

}
.br-about-text {
	border-top: 1px solid var(--gray-900);
}
